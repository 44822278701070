import { NamedLink } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './SectionHowItWorks.module.css';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const SectionHowItWorks = (props) => {
    const { rootClassName, className } = props;

    const classes = classNames(rootClassName || css.root, className);
    return (
        <div className={classes}>
            <div className={css.title}>
                <FormattedMessage id="SectionHowItWorks.titleLineOne" />
            </div>

            <div className={css.steps}>
                <div className={css.step}>
                    <h2 className={css.stepTitle}>
                        <FormattedMessage id="SectionHowItWorks.part1Title" />
                    </h2>
                    <p>
                        <FormattedMessage id="SectionHowItWorks.part1Text" />
                    </p>
                </div>

                <div className={css.step}>
                    <h2 className={css.stepTitle}>
                        <FormattedMessage id="SectionHowItWorks.part2Title" />
                    </h2>
                    <p>
                        <FormattedMessage id="SectionHowItWorks.part2Text" />
                    </p>
                </div>

                <div className={css.step}>
                    <h2 className={css.stepTitle}>
                        <FormattedMessage id="SectionHowItWorks.part3Title" />
                    </h2>
                    <p>
                        <FormattedMessage id="SectionHowItWorks.part3Text" />
                    </p>
                </div>
            </div>

            <div className={css.createListingLink}>
                <NamedLink name="NewListingPage">
                    <FormattedMessage id="SectionHowItWorks.createListingLink" />
                </NamedLink>
            </div>
        </div>
    );
};

SectionHowItWorks.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionHowItWorks.propTypes = {
    rootClassName: string,
    className: string,
};

export default SectionHowItWorks;
